<template>
	<div class="">
		<el-card class="box-card">
			<div class="buyprop">
				<el-button type="primary" icon="el-icon-circle-plus" @click="addlangage()">添加常用语</el-button>
			</div>
			<div class="" style="min-height: 500px;">
				<el-table :data="tableData" v-loading="table_loading" :show-header="false" element-table_loading-text="加载中" class="boderline">
					<el-table-column type="index" width="80"></el-table-column>
					<el-table-column prop="content"></el-table-column>
					<el-table-column width="150">
						<template slot-scope="scope">
							<div class="sort">
								<img v-if="show==false" src="../../assets/images/set/up.png" @mouseenter="enter()" @mouseleave="leave()" @click="orderAdd(scope.$index)">
								<img v-if="show==true" style="transform: rotate(180deg);" src="../../assets/images/set/down.png" @mouseenter="enter()" @mouseleave="leave()" @click="orderAdd(scope.$index)">
								<img v-if="show1==false" style="transform: rotate(180deg);" src="../../assets/images/set/up.png" @mouseenter="enter1()" @mouseleave="leave1()" @click="orderAdd(scope.$index)">
								<img v-if="show1==true" src="../../assets/images/set/down.png" @mouseenter="enter1()" @mouseleave="leave1()" @click="orderMinus(scope.$index)">
							</div>
						</template>
					</el-table-column>
					<el-table-column width="200">
						<template slot-scope="scope">
							<div class="caozuo">
								<el-button type="text" @click="edit(scope.row)">编辑</el-button>
								<el-button type="text" @click="del(scope.row.id)" >删除</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>

			</div>
		</el-card>
		<!-- 编辑常用语 -->
		<el-dialog :visible.sync="editDialogVisible" width="600px" center class="delete_box" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false">
			<div class="wb-tit font18">编辑</div>
			<div class="" style="padding: 50px 100px 30px 100px;text-align: center;position: relative;">
				<span class="closeicon" @click="editDialogVisible = false"><i class="el-icon-error"></i></span>
				<div class="input-box">
					<el-input v-model="editConfig.content" placeholder="请输入常用语"></el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button class="qx" @click="editDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="editCommit">保存</el-button>
				</span>
			</div>
		</el-dialog>


		<!-- 添加常用语弹窗 -->
		<addword @addSuccess="getPhrase"></addword>



	</div>
</template>

<script>
	import Bus from '../../common/bus.js';
	import addword from "/src/components/set/addword.vue";//搜素的简历详情
export default {
	components:{
		addword
	},
	data() {
		return {
			table_loading: false,
			tableData: [],
			show:false,
			show1:false,
			pageInfo: {
				pageNum: 1,
				pageSize: 20,
				count: 1,
				dtos: []
			},
			editDialogVisible:false,//编辑弹窗
			editConfig:{}


		};
	},
	activated() {
		this.fetchData(this.pageInfo.pageNum)
		this.getPhrase()
	},
	methods: {
		enter(){
			this.show = true
		},
		leave(){
			this.show = false
		},
		enter1(){
			this.show1 = true
		},
		leave1(){
			this.show1 = false
		},
		getPhrase(){ //获取常用语
			this.$api.getPhrase('get').then(res=>{
				this.tableData = res.data
			})
		},
		fetchData(page = 1) {
			let vm = this
			// vm.table_loading = true
			vm.pageInfo.pageNum = page
			// vm.formInline.pageNum = page

			vm.table_loading = false;


		},
		edit(data){
			this.editConfig = data
			this.editDialogVisible=true;
		},
		editCommit(){
			let config = []
			config.push(this.editConfig)
			this.$api.savePhrase('post',config).then(()=>{
				this.editDialogVisible = false;
				this.getPhrase()
			})
		},
		addlangage(){
			Bus.$emit('addwordstuts', {orderNumber:this.tableData.length,bool:true});
		},
		del(id){
			this.$api.delPhrase('post',{id:id}).then(()=>{
				this.$message({
					message:'删除成功',
					type:'success'
				})
				this.getPhrase()
			})
		},
		orderAdd(index){
			if(index == 0){
				this.$message({
					message:'该行已经置顶',
					type:'error'
				})
			}else{
				let num1 = this.tableData[index].orderNum
				let num2 = this.tableData[index-1].orderNum
				this.tableData[index].orderNum = num2
				this.tableData[index-1].orderNum = num1
				this.$api.savePhrase('post',this.tableData).then(()=>{
					this.getPhrase()
				})
			}
		},
		orderMinus(index){
			if(index == this.tableData.length-1){
				this.$message({
					message:'该行已经置底',
					type:'error'
				})
			}else{
				let num1 = this.tableData[index].orderNum
				let num2 = this.tableData[index+1].orderNum
				this.tableData[index].orderNum = num2
				this.tableData[index+1].orderNum = num1
				this.$api.savePhrase('post',this.tableData).then(()=>{
					this.getPhrase()
				})
			}
		},

	}


};
</script>

<style lang="less" scoped>
	/deep/.el-card{
		border-radius: 0px;
		border: none;
		box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
	}
	/deep/.el-card__body{
		padding: 0 30px;
	}
	.buyprop{
		margin: 30px 0;
		/deep/ .el-button {
			border-radius: 0px;
			width: 135px;
			background: #00bcff;
			border: none;
		}

	}
	.boderline{
		width: 100%;
		border-right: 1px solid #F3F3F3;
		border-left: 1px solid #F3F3F3;
		border-top: 1px solid #F3F3F3;

	}
	/deep/.el-table td, /deep/.el-table th{
		color: #111111;
		font-size: 14px;
	}
	.caozuo{
		text-align: center;
		.el-button{
			color: #00BCFF;
		}
	}
	.sort{
		img{
			width: 14px;
			height: 18px;
			margin: 0 20px;
		}
	}
	.closeicon {
		position: absolute;
		top: -125px;
		right: 0;
		font-size: 26px;
		color: #f3f3f3;
	}
	.delete_box{
		line-height: 4;
		padding: 30px 0;
		// position: relative;
		// /deep/.el-card__body{
		// 	padding: 50px 30px 30px 30px !important;
		// }
		.wb-tit {
			padding: 17px 0;
			text-align: center;
			background-color: #f3f3f3;
			color: #000000;
			font-weight: 600;
			line-height: 1;
		}
		.input-box{
			/deep/ .el-input__inner{
				border-radius: 0px;
			}
		}
		.db-ts{
			text-align: center;
			color: #666666;
			line-height: 1.5;
		}
		.dialog-footer{

			/deep/ .el-input__inner{
				border-radius: 0px;
			}
			/deep/ .el-button{
				margin-top: 40px;
				border-radius: 0px;
				background: #00bcff;
				border: none;
				width: 190px;
				height: 40px;
			}
			.qx{
				background-color: #fff;
				border: 1px solid #00bcff;
				color: #00bcff;
				margin-right: 10px;
			}
		}
	}

</style>
