<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
				<div class="contentes">
					<img src="../../assets/images/set/sanjingao.png" >
					<div class="tishi font16">请不要填写手机、QQ、微信等联系方式或广告信息，否则系统将封禁您的账号！</div>
					<div class="from-box">
						<div class="ab-left">常用语</div>
						<div class="inputbox">
							<el-input
								type="textarea"
								:autosize="{ minRows: 8, maxRows: 10 }"
								show-word-limit
								placeholder="请输入常用语"
								v-model="content"
								style="width: 450px;"
							></el-input>
							<div class="footer-btn">
								<el-button class="qux" @click="dialogVisible = false">取消</el-button>
								<el-button type="primary" class="btn-next" @click="add" >保存</el-button>
							</div>
						</div>
					</div>


				</div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
			content:'',
			orderNumber:0,

		};
	},
	mounted() {
		Bus.$on('addwordstuts', data => {
			this.dialogVisible = data.bool;
			this.orderNumber = data.orderNumber
			// this.id= data.id;
		});
	},
	methods: {
		closebox() {
			this.dialogVisible = false;
			// Bus.$emit('reportdstuts', false)
		},
		add(){ //确认添加
			if(this.content){
				let config = [{content:this.content,orderNum:this.orderNumber}]
				this.$api.savePhrase('post',config).then(()=>{
					this.$message({
						message:'添加成功',
						type:'success'
					})
					this.dialogVisible = false
          this.content = '';
					this.$emit('addSuccess')
				})
			}else{
				this.$message({
					message:'请输入内容',
					type:'error'
				})
			}
		}
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	padding: 50px 50px 50px 50px;
	.wb-tit {
		padding: 17px 0;
		text-align: center;
		background-color: #f3f3f3;
		color: #000000;
		font-weight: 600;
	}
}

/deep/ .el-textarea__inner {
			border-radius: 0px;
		}

.contentes{
	text-align: center;

	color: #111111;
	img{
		width: 66px;
		height: 59px;
	}
	.tishi{
		margin-top: 40px;
	}
	.from-box{
		margin-top: 40px;
		display: flex;
		.ab-left{
			width: 80px;
			text-align: left;
			padding-left: 14px;
		}
		.inputbox{
			display: flex;
			flex: 1;
			flex-direction: column;
			.footer-btn{
				margin-top: 50px;
				width: 450px;
				/deep/ .el-button {
							border-radius: 0px;
							width: 210px;
							background: #00bcff;
							border: none;
						}
				.qux{
					margin-right: 20px;
					background: #FFFFFF;
					border: 1px solid #00bcff;
					color: #00bcff;
				}
			}
		}

	}
}



</style>
